/* General Styling for the About Us Section */
.about-us {
  font-family: 'Arial', sans-serif;
  padding: 20px 2%;
  margin: 0 auto;
  background-color: #e1b470; /* Matches website background */
  max-width: 100%;
  margin-top:0;
}

/* Heading Styling */
.about-us h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #000;
  margin: 0 0 20px 0;
  width: 100%;
  text-align: center;
  background-color: rgb(198, 153, 84);
  padding: 10px 40px;
  transform: translateY(50px);
  opacity: 0;
  animation: slideUps 0.5s forwards;
  animation-delay: 1.5s;
}

/* Founder Section Styling */
.founder {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  gap: 30px; /* Space between image and content */
  padding: 20px;
  background-color:#e1b470; /* White background for founder section */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  transition: box-shadow 0.3s ease-in-out;
}

.founder:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Hover effect */
}

/* Founder Image Styling (Left-Aligned and Centered Vertically) */
.founder-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 10px;
  flex-shrink: 0;
  margin-right: 20px; /* Space between image and content */
  align-self: center; /* Center the image vertically */
}

/* Founder Details Styling (Right-Aligned) */
.founder-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

/* Founder Name Styling */
.founder-details h5 {
  font-size: 1.4rem;
  font-weight: bold;
  color: #333;
  margin: 0 0 10px;
  text-align: left;
  line-height: 1.4;
}

/* Founder Description Styling */
.founder-details p {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.6;
  margin: 0 0 10px;
  text-align: left;
  max-width: 80%; /* Ensures text does not stretch too far */
}

/* See More Styling */
.see-more {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  font-size: 1rem;
  display: inline-block;
}

/* Modal Styling */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #e1b470; /* White background for a classic feel */
  padding: 40px 60px; /* Add enough padding for a comfortable look */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  width: 70%;
  max-width: 900px;
  z-index: 1000;
  display: none; /* Hidden by default */
  opacity: 0;
  animation: fadeIn 0.3s forwards; /* Fade-in effect */
}

.modal.active {
  display: block; /* Show modal when active */
  opacity: 1;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: none; /* Hidden by default */
}

.modal-overlay.active {
  display: block; /* Show overlay when modal is active */
}

.modal-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

/* Modal Image Styling */
.modal-image {
  width: 205px;
  height: 205px;
  object-fit: cover;
  border-radius: 10px;
}

/* Modal Text Styling */
.modal-text {
  font-size: 1.1rem;
  color: #333;
  line-height: 1.6;
  text-align: left;
  max-width: 500px;
  font-family: 'Arial', sans-serif;
}

/* Close Button */
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 2rem;
  color: #333;
  cursor: pointer;
}

/* Directors Section Styling */
.directors-heading {
  font-size: 2rem;
  font-weight: bold;
  color: #000;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
  font-family: 'Arial', sans-serif;
}

.directors-section {
  background-color: #e1b470;
  padding: 30px 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.director {
  background-color: #e1b470;
  padding: 25px 30px;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 942px;
  text-align: left;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.director:hover {
  transform: scale(1.03);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.director-name {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  font-family: 'Georgia', serif;
}

.director-details {
  font-size: 1.5rem;
  color: #555;
  line-height: 1.6;
  font-family: 'Arial', sans-serif;
  margin: 0;
}

/* Media Queries for Modal Responsiveness */

/* For tablets and smaller screens */
@media (max-width: 1024px) {
  .modal {
    width: 80%; /* Increase width for tablet devices */
    padding: 30px 40px; /* Adjust padding for smaller screens */
  }

  .modal-content {
    flex-direction: column; /* Stack the modal content vertically */
    align-items: center;
  }

  .modal-image {
    width: 200px;
    height: 200px; /* Resize image for smaller screens */
  }

  .modal-text {
    max-width: 90%; /* Make text block more flexible */
    font-size: 1rem; /* Slightly reduce text size */
  }

  .modal-close {
    font-size: 1.5rem; /* Smaller close button for tablets */
  }
}

/* For smaller phones (Mobile) */
@media (max-width: 768px) {
  .modal {
    width: 90%; /* Increase modal width for mobile devices */
    padding: 20px 30px; /* Adjust padding */
  }

  .modal-image {
    width: 180px;
    height: 180px; /* Resize image */
  }

  .modal-text {
    max-width: 95%; /* Allow more flexibility in the text area */
    font-size: 0.95rem; /* Slightly smaller text for better readability */
  }

  .modal-close {
    font-size: 1.3rem; /* Slightly smaller close button */
  }
}

/* For extra small devices (phones) */
@media (max-width: 480px) {
  .modal {
    width: 95%; /* Maximize the width for very small screens */
    padding: 15px 25px; /* Tighten padding */
  }

  .modal-image {
    width: 150px;
    height: 150px; /* Make the image smaller */
  }

  .modal-text {
    font-size: 0.9rem; /* Make the text smaller for very small screens */
    max-width: 90%; /* Ensure text takes up most of the modal space */
  }

  .modal-close {
    font-size: 1.2rem; /* Further reduce close button size */
  }
  .director-name {
    font-size: 21px;
  }
  
  .director-details {
    font-size: 16px;
  }
}

/* Responsive Styling for About Us Section */
@media (max-width: 768px) {
  .about-us {
    padding: 10px;
  }

  .founder {
    flex-direction: column; /* Stack founder image and details vertically */
    align-items: center; /* Center the founder content */
    padding: 15px;
  }

  .founder-image {
    width: 120px;
    height: 120px; /* Resize image for smaller devices */
  }

  .founder-details h5 {
    font-size: 1.5rem;
  }

  .founder-details p {
    font-size: 1.1rem;
    text-align: center; /* Center the text on smaller screens */
  }
}

/* For very small screens (mobile) */
@media (max-width: 480px) {
  .founder-details h5 {
    font-size: 1.3rem;
    text-align: center;
    padding-left: 67px;
  }

  .founder-details p {
    font-size: 1rem;
    text-align: center;
    padding-left: 37px;
  }

  .see-more {
    font-size: 1rem; /* Adjust 'see more' font size for smaller screens */
    margin-left: 112px;
  }
  .directors-heading {
    font-size: 1.8rem;
  }
}
