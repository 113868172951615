/* Donation Form Container */
.boxer{
    width: 100%;
    background-color: #e1b470;
    padding:30px;
}
.donate-container {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    background: #e1b470;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .donate-title {
    text-align: center;
    color: #444;
    margin-bottom: 20px;
  }
  
  .donate-form {
    width: 100%;
  }
  
  .donate-form-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .donate-form-group {
    flex: 1;
  }
  
  .donate-form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 14px;
  }
  
  .donate-form-group input,
  .donate-form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .donate-form-group textarea {
    resize: vertical;
  }
  
  .donate-note {
    color: red;
    font-size: 12px;
    margin-bottom: 10px;
  }
  
  .donate-footer {
    margin-top: 20px;
    font-size: 14px;
  }
  
  .donate-submit-button {
    width: 100%;
    padding: 12px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .donate-submit-button:hover {
    background: #0056b3;
  }
  
  /* Modal Styling */
  .donate-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .donate-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .donate-modal-content h2 {
    margin-bottom: 10px;
  }
  
  .donate-modal-content button {
    margin-top: 20px;
    padding: 10px 20px;
    background: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .donate-modal-content button:hover {
    background: #0056b3;
  }
  
