/* General Container */
.container2 {
    font-family: 'Arial', sans-serif;
    background-color: #e1b470;
    text-align: center;
    padding: 20px;
    min-height: 100vh;
}

/* Animated Heading */
.text-container1 {
    width: 100%;
    background-color: rgb(198, 153, 84);
    padding: 20px;
    font-size: 2.8rem;
    font-weight: 600;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    opacity: 0;
    transform: translateY(-50px);
    animation: slideUp 0.5s forwards;
    animation-delay: 0.6s;
}

.text-container1 h1 {
    transform: translateY(50px);
    opacity: 0;
    animation: slideUps 0.5s forwards;
    animation-delay: 1.5s;
}

/* Grid Section for Content */
.grid2 {
    display: flex;
    justify-content: center;
    align-items: center; /* Ensure content is centered vertically */
    gap: 40px;
    background-color: #e1b470;
    border-radius: 8px;
    padding: 0 50px;
    flex-wrap: wrap; /* Ensure items wrap on smaller screens */
}

.section2 {
    text-align: center;
    margin: 20px;
    border-radius: 8px;
    padding: 20px;
    background: #e1b470;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    width: 300px; /* Set a fixed width for each section */
    flex: 1; /* Ensure sections can grow/shrink */
    max-width: 100%; /* Prevent overflowing */
}

/* Labels and Descriptions */
.label2 {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 15px;
    color: #1d1d1d;
}

.description1 {
    font-size: 1.4rem;
    color: #555;
    margin: 10px 0 20px;
}

/* WhatsApp Button */
.whatsapp-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 12px 20px;
    background-color: #25d366;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    text-decoration: none;
    font-size: 18px;
    transition: background-color 0.3s ease, transform 0.2s;
}

.whatsapp-button:hover {
    background-color: #1da955;
    transform: scale(1.05);
}

.whatsapp-icon {
    width: 28px;
    height: auto;
    vertical-align: middle;
}

/* Info Section */
.info-section {
    margin-top: 30px;
    padding: 20px;
}

.info-section h3 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #333;
}

.info-list {
    list-style: none;
    padding: 0;
    font-size: 1.4rem;
    align-items: center;
    color: #555;
}

.info-list li {
    margin-bottom: 10px;
    padding-left: 20px;
    text-align: center;
}

/* Footer Line */
.line1 {
    height: 4px;
    background-color: black;
    width: calc(100% - 100px);
}

/* Responsive Design */

/* For Tablets and Small Desktops */
@media (max-width: 850px) {
    .grid2 {
        flex-direction: column;
        padding: 0 30px;
    }

    .info-section h3 {
        font-size: 1.6rem;
    }

    .info-list {
        font-size: 1.2rem;
    }
}

/* For Mobile Devices */
@media (max-width: 650px) {
    .grid2 {
        padding: 0 20px;
    }

    .whatsapp-button {
        font-size: 16px;
        padding: 10px 16px;
    }

    .whatsapp-icon {
        width: 24px;
    }

    .info-section h3 {
        font-size: 1.4rem;
    }

    .info-list {
        font-size: 1rem;
    }

    .description1 {
        font-size: 1.2rem;
    }

    .label2 {
        font-size: 1.6rem;
    }
}
