
.text-container {
    width: 100%;
    /* Adjust this value based on the max-width of your content */
    background-color: rgb(198, 153, 84); /* Semi-transparent background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); /* Add shadow for better contrast */
    opacity: 0;
    transform: translateY(-50px);
    animation: slideUp 0.5s forwards;
    animation-delay: 0.6s; /* Slight delay before animation starts */
}
.link {
  text-decoration: none;
  color: inherit;
}

.link:hover {
  color: #e1b470; /* Optional hover effect */
}

.text-container h1{
  transform: translateY(50px);
    opacity: 0;
    animation: slideUps 0.5s forwards;
    animation-delay: 1.5s; /* Delay after the container animation */
}
@keyframes slideUp {
  to {
      opacity: 1;
      transform: translateY(0);
  }
}
@keyframes slideUps {
  to {
      opacity: 1;
      transform: translateY(0);
  }
}
.grid1{
    display: flex;
    justify-content: center;
    padding: 0 50px;
    gap: 40px;
            background-color: #e1b470;
}
.section1 {
    width: 100%; /* Default width for larger screens */
    text-align: center;
   
  margin: 20px;
    border-radius: 8px;
    position: relative;
    transition: all 0.3s ease;
  }
  
.label1 {
    font-size: 2rem;
    font-weight: 400;
    background-color: #f3c67b;
    padding: 5px 10px;
    border-radius: 10px;
    color: #000;
   
    
  }
  .image1 {
    width: 100%;
    height: auto;
    border-radius: 8px;
    border-width: 2px;
    border-color: black;
    border-style: solid;
    margin-top: 10px;
  }
  .line1 {
    
    height: 4px;
    background-color: black;
    margin: 0 auto; /* Centers the line horizontally */
    width: calc(100% - 100px); /* Matches the padding of .grid1 (2 * 50px) */
  }
  @media (max-width:850px){
    .grid1{
        display: flex;
        flex-direction: column;
    }
   
  }
  @media (max-width:650px){
    .grid1{
        display: flex;
        flex-direction: column;
       padding: 10px 40px;
    }
  }
