/* General Container Styling */
.container11 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px;
    min-height: 72vh;
    position: relative;
}

/* Main Text Container Styling */
.text-container1 {
    width: 100%;
    background-color: rgb(198, 153, 84);
    border-radius: 10px;
    margin-top: 40px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    padding: 20px;
}

/* Heading Styling */
.text-container1 h1 {
    font-size: 3rem;
    margin: 0;
    animation: slideUps 0.5s forwards;
    animation-delay: 1.5s;
}

/* List Styling */
.about-ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    text-align: left;
}

.about-ul-li {
    font-size: 1rem;
    font-weight: 400;
    color: black;
}

/* PDF Button Styling */
.pdf-button {
    display: inline-block;
    margin: 20px auto; /* Center align */
    padding: 15px 30px;
    background-color: rgb(198, 153, 84);
    color: black;
    font-size: 1rem;
    font-weight: bold;
    border: none;
    border-radius: 25px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
}

/* Button Hover Effect */
.pdf-button:hover {
    background-color: rgb(211, 180, 134);
    box-shadow: 0px 6px 12px rgba(238, 194, 129, 0.8);
    transform: scale(1.05);
}

/* Responsive Design */

/* Medium Devices (Tablets) */
@media (max-width: 768px) {
    .text-container1 {
        padding: 15px;
    }

    .text-container1 h1 {
        font-size: 2.2rem;
    }

    .about-ul {
        gap: 15px;
        padding: 15px;
    }

    .about-ul-li {
        font-size: 0.9rem;
    }

    .pdf-button {
        font-size: 0.9rem;
        padding: 12px 25px;
        margin: 20px auto;
        position: static;
    }
}

/* Small Devices (Phones) */
@media (max-width: 480px) {
    .text-container1 {
        padding: 10px;
    }

    .text-container1 h1 {
        font-size: 1.8rem;
        margin-bottom: 10px;
    }

    .about-ul {
        gap: 10px;
        padding: 10px;
    }

    .about-ul-li {
        font-size: 0.8rem;
        line-height: 1.5;
    }

    .pdf-button {
        font-size: 0.8rem;
        padding: 10px 20px;
        margin-top: 15px;
    }
}

/* Large Devices (1440px and up) */
@media (min-width: 1440px) {
    .text-container1 h1 {
        font-size: 2.5rem;
    }

    .about-ul-li {
        font-size: 1.2rem;
    }

    .pdf-button {
        font-size: 1rem;
        padding: 15px 30px;
    }
}

/* Ultra-Wide Screens (1920px and up) */
@media (min-width: 1920px) {
    .text-container1 h1 {
        font-size: 2.8rem;
    }

    .about-ul-li {
        font-size: 1.3rem;
    }

    .pdf-button {
        font-size: 1.1rem;
        padding: 16px 32px;
    }
}

/* Extra-Wide Screens (2560px and up) */
@media (min-width: 2560px) {
    .text-container1 h1 {
        font-size: 3rem;
    }

    .about-ul-li {
        font-size: 1.5rem;
    }

    .pdf-button {
        font-size: 1.2rem;
        padding: 18px 35px;
    }
}

/* Animation Keyframes */
@keyframes slideUps {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
