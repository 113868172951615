/* General Container */
.share-page-container {
    text-align: center;
    font-family: 'Arial', sans-serif;
    margin: 20px;
    padding: 20px;
    background-color: #fafafa;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Header and Description */
  .share-page-container h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .share-page-container p {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 20px;
  }
  
  /* Share Buttons Container */
  .share-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 20px;
  }
  
  /* Individual Share Button */
  .share-button {
    display: inline-block;
    padding: 12px 25px;
    font-size: 1.1rem;
    color: #fff;
    text-decoration: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  /* Button Hover Effects */
  .share-button:hover {
    opacity: 0.8;
    transform: scale(1.05);
  }
  
  /* Specific Button Colors */
  .facebook-button {
    background-color: #3b5998;
  }
  
  .twitter-button {
    background-color: #1da1f2;
  }
  
  .linkedin-button {
    background-color: #0077b5;
  }
  
  /* Media Query for Responsiveness */
  @media (max-width: 768px) {
    .share-button {
      padding: 10px 20px;
      font-size: 1rem;
    }
    .share-page-container {
      margin: 10px;
      padding: 15px;
    }
  }
  
