/* General Page Container */
.page-container {
  background-color: #e1b470;
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

/* Form Container */
.form-container {
  max-width: 800px;
  width: 90%;
  padding: 30px;
  background: #f3c67b;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
}

/* Header and Description */
.form-header {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 10px;
  font-size: 2rem;
}

.form-description {
  text-align: center;
  color: #555;
  margin-bottom: 20px;
  font-size: 1.2rem;
}

.info-section h3 {
  color: #9b6f29;
  margin-top: 15px;
  font-size: 1.5rem;
}

.info-section ul {
  color: #555;
  padding-left: 20px;
}

/* Form Fields */
.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #2c3e50;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

textarea {
  resize: vertical;
  min-height: 80px;
}

/* Submit Button */
.submit-button {
  width: 100%;
  padding: 12px;
  background: rgb(198, 153, 84);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

.submit-button:hover {
  background: #a06d1c;
}

/* Button Container for Alignment */
.button-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.button-container .submit-button {
  width: auto;
  min-width: 200px;
}

/* Skills Checkboxes */
.skills-checkboxes label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
}

.skills-checkboxes label input {
  margin-right: 5px;
}

/* Responsive Styles */

/* Tablets (768px and below) */
@media (max-width: 768px) {
  .form-container {
    padding: 20px;
  }

  .form-header {
    font-size: 1.8rem;
  }

  .form-description {
    font-size: 1rem;
  }

  .info-section h3 {
    font-size: 1.3rem;
  }

  label,
  input,
  textarea {
    font-size: 14px;
  }

  .submit-button {
    font-size: 14px;
    padding: 10px;
  }

  .button-container {
    gap: 10px;
  }

  .button-container .submit-button {
    width: auto;
  }
}

/* Mobile Devices (480px and below) */
@media (max-width: 480px) {
  .form-container {
    padding: 15px;
    box-shadow: none;
  }

  .form-header {
    font-size: 1.5rem;
  }

  .form-description {
    font-size: 0.9rem;
  }

  .info-section h3 {
    font-size: 1.1rem;
  }

  label,
  input,
  textarea {
    font-size: 12px;
  }

  .submit-button {
    font-size: 14px;
    padding: 8px;
  }

  .button-container {
    gap: 10px;
  }

  .button-container .submit-button {
    width: auto;
  }
}
