/* Container for the home page */
.home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e1b470;
}

/* Earth Text */
.earthText {
    font-size: 2rem;
    font-weight: 700;
    color: #000;
    text-align: center;
    margin: 20px 0;
}

/* Image of Earth with hands */
.background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
    transform: translateY(50px);
    opacity: 0;
    animation: slideUps 4s forwards;
    animation-delay: 1s;
}

/* Text container */
.text-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Stack items vertically */
}

.mission-heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; /* Ensure the container spans the available width */
    max-width: 800px; /* Optional: Set a max-width for better design */
    overflow: hidden; 
}

.mission-heading {
    text-align: center;
    flex: 1; /* Allow heading to take up available space */
}
a{
    text-decoration:none;
   
    
}

.pdf-button {
    float:right;/* Push the button to the right */
     font-size:18px;
}


.pdf-button:hover {
    background-color: #e1b470;
    transform: scale(1.05);
}

.pdf-button:active {
    transform: scale(1);
}

/* Image and text row */
.image-text-row {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
}

/* Container for the additional image */
.image-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
}

.tech-image {
    width: 100%;
    height: 360px;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

/* Right text container */
.right {
    flex: 2;
    margin-left: 20px;
}

/* Styling for headings and paragraphs */
.text-container h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #000;
    margin: 0 0 20px 0;
    text-align: center;
    transform: translateY(50px);
    opacity: 0;
    animation: slideUps 0.5s forwards;
    animation-delay: 1.5s;
}

.text-container h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
    margin: 20px 0 10px 0;
}

.home-ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-decoration: dotted;
    padding: 40px;
}

.home-ul li {
    font-size: 1.2rem;
    font-weight: 400;
    color: black;
}

/* Responsive styling */
@media (max-width: 768px) {
    .mission-heading-container {
        flex-direction: column; /* Stack heading and button vertically */
        align-items: center;
        gap: 20px; /* Reduce space between heading and button when stacked */
    }

    .mission-heading {
        font-size: 2rem;
    }

    .pdf-button {
        margin-top: 10px; /* Add space between heading and button when stacked */
    }

    .image-text-row {
        flex-direction: column;
        align-items: center;
    }

    .earthText {
        font-size: 1.5rem;
    }

    .home-ul {
        padding: 20px;
    }

    .home-ul li {
        font-size: 0.8rem;
    }

    .text-container h1 {
        font-size: 1.5rem;
    }

    .text-container h2 {
        font-size: 1.5rem;
    }

    .image-container {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .text-container h1 {
        font-size: 2rem;
    }

    .text-container h2 {
        font-size: 1.2rem;
    }

    .text-container p {
        font-size: 0.9rem;
    }
}

@media (max-width: 600px) {
    .image-container {
        margin-left: 0;
    }
}

@media (max-width: 480px) {
    .text-container h1 {
        font-size: 1.5rem;
    }

    .text-container h2 {
        font-size: 1rem;
    }

    .text-container p {
        font-size: 0.8rem;
    }

    .tech-image {
        width: 100%;
    }
}
