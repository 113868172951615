.mission-container {
    background-color: #e1b470; /* Parchment-like background */
    padding: 40px;
    max-width: 90%; /* Limit width for large screens */
    border: 1px solid #ffc955; /* Classic golden border */
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-family: 'Times New Roman', Times, serif;
}

.mission-header h1 {
    font-size: 36px;
    color: #7c4b35; /* Deep, warm brown */
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    border-bottom: 2px solid #d4b886;
    padding-bottom: 10px;
}

.mission-content {
    margin-top: 20px;
    background-color: #f8e8d0; /* Subtle parchment tone */
    padding: 20px;
    border-radius: 4px;
    border-left: 4px solid #ffc955;
}

.mission-content ul {
    list-style-type: disc;
    margin: 0;
    padding: 0 20px;
    line-height: 1.8;
}

.mission-content ul li {
    font-size: 18px;
    color: #4a3c2f;
    margin-bottom: 10px;
    padding-left: 15px;
    position: relative;
}

.mission-content ul li::before {
    content: '•';
    color: #ffc955; /* Classic golden bullet */
    font-size: 20px;
    position: absolute;
    left: 0;
    top: 0;
}

/* Responsive styling */
@media (max-width: 1024px) {
    .mission-container {
        padding: 30px;
    }

    .mission-header h1 {
        font-size: 32px;
    }

    .mission-content ul li {
        font-size: 17px;
    }
}

@media (max-width: 768px) {
    .mission-container {
        padding: 20px;
        width: 95%; /* Further reduce width for smaller screens */
    }

    .mission-header h1 {
        font-size: 28px;
    }

    .mission-content ul {
        padding: 0 10px; /* Reduce padding for smaller screens */
    }

    .mission-content ul li {
        font-size: 16px;
    }
}

@media (max-width: 480px) {
    .mission-container {
        padding: 15px;
        width: 100%;
    }

    .mission-header h1 {
        font-size: 24px;
    }

    .mission-content ul li {
        font-size: 15px;
        padding-left: 10px; /* Adjust padding for tight spacing */
    }

    .mission-content ul {
        padding: 0 5px; /* Extra small padding on smallest screens */
    }
}
